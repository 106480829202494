<template>
    <div class="virtual" :class="{m_virtual:$store.state.screenType}">
        <TitleH>
            <transition slot="header">
                <span>{{$t('Nav[1].menuList[4]').toUpperCase()}}</span>
            </transition>
        </TitleH>
        <div class="virtual_content"  v-if="virtual.length">
            <div class="virtual_content_item" v-for="(value,index) in virtual" :key="index" >
                <div class="preview">
                    <img :src="imgUrl(baseUrl,value.imgList[0].ImageUrl,'@t:1920x1920>')" alt="">
                </div>
                <div class="about">
                    <div class="about_title">{{value.title}}</div>
                    <div class="about_content" v-html="value.text"></div>
                    <!-- <div class="about_content" value="value.text"></div> -->
                    <!-- <span class="bb_content">{{ textdata[0] }}</span> -->

                    <div class="next"></div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import TitleH from '../../../components/Index/TitleH'
import { imgUrl } from "@/utils/urlutils"
import {mapState} from 'vuex'
export default {
    name: "VirtualModule",
    props:['virtual'],
    components: {
        TitleH
    },
    methods: {
        imgUrl
    },
    computed:{
        ...mapState(['baseUrl'])
    }

}
</script>


<style scoped lang="scss">
$maxWidth: 1920px;
/*-- -------VirtualModule---------- --*/
.virtual {
    max-width: $maxWidth;
    padding: 4.125rem 7.5rem;
    margin: 0 auto;

    &_content {

        &_item {
            margin-top: 4.875rem;
            .preview {
                width: 100%;
                height: 31.4375rem;
                overflow: hidden;
                img {
                    width: 100%;
                }
            }

            .about {
                width: 100%;
                height: 9.75rem;
                // background-color: #F7F9FB;
                margin-top: 1.25rem;
                display: flex;
                justify-content: left;
                position: relative;
                background-size:cover;
                margin-bottom: 6.0rem;
                &_title{
                    width:23.125rem ;
                    height: 4.5rem;
                    background-color: #F1F1F3;
                    margin-right: 1.375rem;
                    font-size: 1.45rem;
                    line-height: 3.0rem;
                }
                &_content{
                    // width: 72.75rem;
                    width:100%;
                    // height: 5.75rem;
                    background-color: #F1F1F3;
                    
                    ::v-deep p{
                        font-size: 1.45rem;
                    }
                    
                    font-family:'AlibabaPuHuiTiL'
                    //font-size: 1.35rem;
                }
                .next{
                    position: absolute;
                    right: 1rem;
                    bottom: 1.5rem;
                    width: 1.8125rem;
                    height: 1.25rem;
                    background-image: url('../../../assets/next_02.png');
                    background-size: 100% auto;
                }
            }
        }
    }
}

@import 'css/m-virtual';
</style>
