<template>
    <div class="Business" :class="{m_business:$store.state.screenType}">
        <Navbar @barClick="index"></Navbar>
        <SwiperCom :list="banners" class="swiper" :class="{m_swiper:$store.state.screenType}" v-if="banners.length"></SwiperCom>
        <ScrollBar :menulist="$t('Nav[1]')" @barClick="index"></ScrollBar>
        <!--赛事组织及内容制作-->
        <CompetitionCom class="scrollBar" :remote="remoteList" :customer="mainCustomerList" :main="mainBusList"
                        :global="globalRaceInfo" :remotelist="remoteContents" :tech="techLists"></CompetitionCom>
        <!--电子商业化-->
        <CommerceModule class="scrollBar" :serveic="ServiceList"></CommerceModule>
        <!--社群运营-->
        <AssociationModule class="scrollBar" :actors="ActorsList" :social="socailGroup"></AssociationModule>
        <!--电竞商业综合体-->
        <SystemModule class="scrollBar" :system="gameBusiMultis"></SystemModule>
        <!--虚拟制作-->
        <VirtualModule class="scrollBar" :virtual="unrealList"></VirtualModule>
        <!--电竞教育-->
        <EducationModule class="scrollBar" :education="gameSchool"></EducationModule>
        <Footers></Footers>
        <top></top>
    </div>
</template>

<script>
import Navbar from '../../components/Nav/Navbar'
import SwiperCom from '../../components/SwiperCom'
import Footers from '../../components/Footer/Footers'
import CompetitionCom from './BusinessModule/CompetitionCom'
import CommerceModule from './BusinessModule/CommerceModule'
import AssociationModule from './BusinessModule/AssociationModule'
import SystemModule from './BusinessModule/SystemModule'
import VirtualModule from './BusinessModule/VirtualModule'
import EducationModule from './BusinessModule/EducationModule'
//引入mixins，（点击滚动到指定位置）
import { result } from '../../tools/ScrollBar'
//位置条
import ScrollBar from '../../components/ScrollBar'

export default {
    name: "Business",
    components: {
        Navbar,
        SwiperCom,
        ScrollBar,
        Footers,
        CompetitionCom,
        CommerceModule,
        AssociationModule,
        SystemModule,
        VirtualModule,
        EducationModule
    },
    mixins: [result],
    data () {
        return {
            imgList: [
                require('../../assets/banner/banner_05.png'),
                require('../../assets/banner/banner_05.png')
            ],

            meta: {}, //head头部内容
            banners: [],//轮播图
            mainBusList: [],//主要承办赛事
            mainCustomerList: [],//主要客户
            globalRaceInfo: [],//全球赛事
            remoteList: [], //远程制作模块右边小图片，
            remoteContents: [],//远程制作模块，
            ActorsList: [], //社群运营-公司艺人
            ServiceList: [], //电竞商业化-服务品牌
            socailGroup: [],//社群运营-社群运营
            gameBusiMultis: [],//电竞商业综合体
            unrealList: [],//虚拟制作
            gameSchool: {},//电竞教育
            techLists: []//技术

        }
    },
    methods: {
        index (value) {
            this.scrollClick(value)
        },
        //请求head数据
        getList () {
            this.$http.getPage(this.$store.state.languageIndex, 3).then(res => {
                //console.log(res)
                this.meta = res.data.data
                this.banners = res.data.data.banners
                //console.log(this.meta)
                this.$getMedia()
            })
        },
        getMainList () {
            this.$http.getSmallImage(this.$store.state.languageIndex).then(res => {
                //console.log(res.data.data)
                this.mainBusList = res.data.data.mainBusList
                this.mainCustomerList = res.data.data.mainCustomerList
                this.globalRaceInfo = res.data.data?.globalRaceInfo
                this.remoteList = res.data.data.remoteList
                this.remoteContents = res.data.data.remoteContents
                this.ActorsList = res.data.data.ActorsList
                this.ServiceList = res.data.data.ServiceList
                this.socailGroup = res.data.data.socailGroup
                this.gameBusiMultis = res.data.data?.gameBusiMultis
                this.unrealList = res.data.data.unrealList
                this.gameSchool = res.data.data.gameSchool
                this.techLists = this.arrayChange(res.data.data.techLists, 3)
            })
        },
        //用来处理将数组分割成特定的数量
        arrayChange (data, num) {
            let index = 0
            let array = []
            while (index < data.length) {
                array.push(data.slice(index, index += num));
            }
            return array;
        }

    },
    created () {
        this.getList()
        this.getMainList()
    },
    watch: {
        //监听vuex中的语言状态，当发生改变的时候，更新页面中的数据
        '$store.state.languageIndex' () {
            this.getList()
            this.getMainList()
        }
    }

}
</script>


<style scoped lang="scss">
$maxWidth: 1920px;
/*-- -------Business---------- --*/
.Business {
    font-size: 0;

    .swiper {
        padding-top: 6.25rem !important;
    }

    ::v-deep [class^='swiper-button'] {
        display: none;
    }

    .public {
        max-width: $maxWidth;
        margin: 0 auto;
        padding: 7.5rem;
        box-sizing: border-box;
    }

}

@import "./m-business";
</style>
