<template>
    <div :class="{m_div:$store.state.screenType}">
        <div class="association" v-if="Object.keys(social).length>0">
            <TitleH>
                <transition slot="header">
                    <span>{{ $t('Nav[1].menuList[2]').toUpperCase() }}</span>
                </transition>
            </TitleH>
            <div class="association_content">
                <div class="association_content_logo">
                    <img :src="imgUrl(baseUrl,social.imgList[0].ImageUrl)" alt="" >
                </div>
                <div class="association_content_explain">
                    <div v-html="social.text" class="qq-text"></div>
                    <div class="association_content_explain_logo">
                        <img :src="imgUrl(baseUrl,value, '@t:196x196>')" alt="" v-for="value in social.smallImgList">
                    </div>
                </div>
            </div>
        </div>
        <!--公司艺人-->
        <div class="artist" >
            <TitleCom :name="$t('Business.celebrity').toUpperCase()"></TitleCom>
            <div class="main-each" v-if="actors.length">
                <div v-for="val in actors" >
                    <img :src="imgUrl(baseUrl,val,'@t:256x256>')" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TitleH from '../../../components/Index/TitleH'
import TitleCom from '../../../components/Index/TitleCom'
import { imgUrl } from "@/utils/urlutils"
import { mapState } from 'vuex'
export default {
    name: "AssociationModule",
    components: {
        TitleH,
        TitleCom
    },
    props: ['actors', 'social'],
    data () {
        return {
        }
    },
    methods: {
        imgUrl
    },
    computed: {
        ...mapState(['baseUrl'])
    }
}
</script>


<style scoped lang="scss">
$maxWidth: 1920px;
/*-- -------AssociationModule---------- --*/
.association {
    background-color: #FBFBFB;
    padding: 4.125rem 7.5rem;
    max-width: $maxWidth;
    margin: 0 auto;

    ::v-deep .en_title {
        line-height: 2.125rem !important
    }

    &_content {
        padding-top: 7.5rem;
        display: flex;
        justify-content: space-between;

        &_logo {
            width: 45%;
            height: 47.1875rem;
            background: #ED111C;
            position: relative;

            img {
                width: 35.5rem;
                height: 11.4375rem;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                display: block;
            }
        }

        &_explain {
            width: 45%;
            padding: 2.25rem 0;

            .qq-text {

              ::v-deep p{
                  font-size: 1.875rem;
                  line-height: 3rem;
                  margin-bottom: 5.9375rem;
                  font-family:'AlibabaPuHuiTiL';
                  text-align: justify;
              }

            }


            &_logo {
                display: flex;
                justify-content: space-around;
                align-items: center;

                img {
                    width: 8.875rem;
                }
            }
        }
    }
}

.artist {
    padding: 6.25rem 7.5rem;
    max-width: $maxWidth;
    margin: 0 auto;

    .main-each {
        padding-top: 6.25rem;
        display: flex;
        justify-content: left;
        flex-wrap: wrap;
        margin: 0 auto;

        div {
            width: 8%;
            height: 8.5625rem;
            // width: 8.5625rem;
            background-color: #9999;
            margin-bottom: 1.5rem;
            margin-right: 2%;
            font-size: 0;

            &:nth-child(10n) {
                margin-right: 0;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}

@import "css/m-association";
</style>
