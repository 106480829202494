<template>
    <div class="commerceModule" :class="{'m-commerce':$store.state.screenType}" >
        <TitleH>
            <transition slot="header">
                <span>{{ $t('Nav[1].menuList[1]').toUpperCase() }}</span>
            </transition>
        </TitleH>
        <div class="commerce" v-if="serveic.length">
            <TitleCom :name="$t('Business.brands').toUpperCase()"></TitleCom>
            <div class="commerce_logo">
                <img :src="imgUrl(baseUrl,serveic[0],'@t:2048x2048>')" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import TitleH from '../../../components/Index/TitleH'
import TitleCom from '../../../components/Index/TitleCom'
import { imgUrl } from "@/utils/urlutils"
import { mapState } from 'vuex'
export default {
    name: "CommerceModule",
    components: {
        TitleH,
        TitleCom
    },
    props:['serveic'],
    data(){
        return{

        }
    },
    methods: {
        imgUrl
    },
    computed: {
        ...mapState(['baseUrl'])
    }
}
</script>


<style scoped lang="scss">
$maxWidth: 1920px;
/*-- -------CommerceModule---------- --*/
.commerceModule {
    max-width: $maxWidth;
    margin: 0 auto;
    padding: 3rem 7.5rem;
    box-sizing: border-box;

    ::v-deep .en_title {
        line-height: 2.125rem !important
    }

    .commerce {
        margin-top: 5rem;

        &_logo {
            padding: 4.125rem 0;

            img {
                margin-right: 2.5rem;
                width: 100%;
            }
        }

    }
}

@import "css/m-commerce";
</style>
