<template>
    <div
        class="competition"
        :class="{ m_competition: $store.state.screenType }"
    >
        <!--赛事组织及内容制作-->
        <div class="competition_game">
            <TitleH>
                <transition slot="header">
                    <span style="">{{
                        $t("Nav[1].menuList[0]").toUpperCase()
                    }}</span>
                </transition>
            </TitleH>
            <div class="competition_game_main public">
                <TitleCom
                    :name="$t('Business.events').toUpperCase()"
                ></TitleCom>
                <div class="main-each">
                    <div v-for="val in main">
                        <img
                            :src="imgUrl(baseUrl, val, '@t:256x256>')"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>
        <!--主要合作客户-->
        <div class="competition_client public">
            <TitleCom :name="$t('Business.customer').toUpperCase()"></TitleCom>
            <div class="main-each">
                <div v-for="val in customer">
                    <img :src="imgUrl(baseUrl, val, '@t:256x256>')" alt="" />
                </div>
            </div>
        </div>
        <!--全球赛事-->
        <div class="competition_game">
            <div class="competition_game_main public">
                <TitleCom
                    :name="$t('Business.global').toUpperCase()"
                ></TitleCom>
                <div class="game_main" v-if="Object.keys(global).length > 0">
                    <div class="game_main_name">
                        <span>{{ global.title }}</span>
                        <div
                            class="game_main_name_text"
                            v-if="!$store.state.screenType"
                        >
                            <div v-html="global.text"></div>
                            <p>......</p>
                        </div>
                        <div
                            class="game_main_name_text"
                            v-if="$store.state.screenType"
                        >
                            <div v-html="global.text"></div>
                            <!-- <view v-html="global.text" class="small"></view> -->

                            <div class="all">
                                {{ $t("Index.view").toUpperCase() }}
                                <div class="next"></div>
                            </div>
                        </div>
                    </div>
                    <div class="game_main_img">
                        <span>海外线下赛事</span>
                        <div class="game_main_img_img">
                            <img :src="imgUrl(baseUrl,global.imgList[0].ImageUrl,'@t:1280x1280>')" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--技术-->
        <div class="competition_skill public">
            <TitleCom :name="$t('Business.tech').toUpperCase()"></TitleCom>
            <CompetitionSwiper
                :list="tech"
                v-if="tech.length"
            ></CompetitionSwiper>
        </div>
        <!--远程制作-->
        <div class="competition_game">
            <div class="competition_game_make public">
                <TitleCom
                    :name="$t('Business.remote').toUpperCase()"
                ></TitleCom>
                <div class="make">
                    <div class="make_content">
                        <div
                            class="make_content_text"
                            v-for="(value, index) in remotelist"
                            :key="index"
                        >
                            <div v-html="value.text" class="qq-text"></div>

                            <!-- <div class="m_swipers">
                                <CompetitionSwiperEl></CompetitionSwiperEl>
                            </div> -->
                        </div>
                        <div class="m_swipers">
                            <CompetitionSwiperEl
                                :list="remote"
                            ></CompetitionSwiperEl>
                        </div>
                    </div>
                    <div class="make_imgBox" v-if="remote.length">
                        <div
                            class="make_imgBox_shows"
                            v-for="(value, index) in remote"
                        >
                            <img
                                :src="imgUrl(baseUrl, value, '@t:640x640>')"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TitleH from "../../../components/Index/TitleH";
import TitleCom from "../../../components/Index/TitleCom";
import CompetitionSwiper from "./CompetitionModule/CompetitionSwiper";
import CompetitionSwiperEl from "./CompetitionModule/CompetitionSwiperEl";
import { imgUrl } from "@/utils/urlutils";
import { mapState } from "vuex";

export default {
    name: "CompetitionCom",
    components: {
        TitleH,
        TitleCom,
        CompetitionSwiper,
        CompetitionSwiperEl,
    },
    props: ["remote", "customer", "main", "global", "remotelist", "tech"],
    data() {
        return {
            skillList: [
                {
                    url: "",
                    title: "AR增强现实",
                    titleList:
                        "2018 KPL春季赛总决赛，VSPO以四面高清大屏AR效果展示大鲲形象开拓赛事观看全新体验",
                },
                {
                    url: "",
                    title: "实时动捕",
                    titleList:
                        "2020年PUBG MOBILE职业联赛东南亚赛区 S2总决赛， VSPO首次将实时动捕技术应用到电竞赛事中",
                },
                {
                    url: "",
                    title: "AR增强现实",
                    titleList:
                        "2018 KPL春季赛总决赛，VSPO以四面高清大屏AR效果展示大鲲形象开拓赛事观看全新体验",
                },
            ],
        };
    },
    methods: {
        imgUrl,
    },
    computed: {
        ...mapState(["baseUrl"]),
    },
};
</script>

<style scoped lang="scss">
.public {
    max-width: 1920px;
    margin: 0 auto;
    padding: 7.5rem;
    box-sizing: border-box;

    .main-each {
        padding-top: 6.25rem;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        &:after {
            flex-grow: 1;
        }

        div {
            display: flex;
            justify-content: center;
            align-items: center;
            // width: 8%;
            height: 8.5625rem;
            // widows: 8.5625rem; 最小行数?
            //background-color: #F1F0EE;
            margin-bottom: 1.5rem;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            font-size: 0;
            border-radius: 0.375rem;
            overflow: hidden;

            &:nth-child(10n) {
                margin-right: 0;
            }

            img {
                // width: 100%;
                height: 100%;
            }
        }
    }
}

/*-- -------CompetitionCom---------- --*/
.competition {
    padding: 4.375rem 0;

    ::v-deep .en_title {
        line-height: 2.125rem !important;
    }

    &_game {
        background-color: #fbfbfb;
        padding-top: 3.75rem;

        &_main {
            padding: 3.75rem 7.5rem;

            .game_main {
                padding: 2.0625rem 0;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                &_name {
                    width: 27%;
                    background-color: #fff;
                    padding: 2.0625rem;

                    &_text {
                        margin-top: 2.0625rem;
                        line-height: 1.875rem;
                        font-size: 1rem;
                        font-family: "AlibabaPuHuiTiL";
                    }

                    .small {
                        ::v-deep
                            p:not(:first-child, :nth-child(2), :nth-child(3)) {
                            display: none;
                        }
                    }

                    .all {
                        position: relative;
                        width: auto;
                        max-width: 12.5rem;
                        height: 2.5rem;
                        background-color: #ed111c;
                        padding: 0 1rem;
                        box-sizing: border-box;
                        color: #fff;
                        line-height: 2.5rem;
                        border-radius: 20rem;
                        margin-top: 1.125rem;
                        font-size: 0.875rem;

                        .next {
                            position: absolute;
                            top: calc(50% - 0.4375rem);
                            right: 1rem;
                            width: 1.3125rem;
                            height: 0.875rem;
                            background-image: url("../../../assets/banner_next.png");
                            background-size: 100% auto;
                        }
                    }
                }

                &_img {
                    width: 68%;
                    text-align: center;
                    margin-top: 1.875rem;

                    &_img {
                        padding-top: 2.5rem;

                        img {
                            width: 100%;
                        }
                    }
                }

                span {
                    font-size: 1.875rem;
                    position: relative;
                    font-family: "AlibabaPuHuiTiB";

                    &:before {
                        position: absolute;
                        top: 104%;
                        left: -3%;
                        content: "";
                        width: 106%;
                        height: 0.125rem;
                        background-color: red;
                        border-radius: 0.125rem;
                    }

                    &:after {
                        position: absolute;
                        content: "";
                        width: 0;
                        height: 0;
                        top: 104%;
                        left: calc(52% - 0.15625rem);
                        border-style: solid;
                        border-width: 0.5rem 0.25rem 0 0.25rem;
                        border-color: red transparent transparent transparent;
                    }
                }
            }
        }

        /*远程操作*/
        &_make {
            padding: 3.5rem 7.5rem;

            .make {
                padding-top: 6.6875rem;
                display: flex;
                justify-content: space-between;

                &_content {
                    width: 40%;

                    &_text {
                        padding-top: 0rem;

                        ::v-deep .qq-text {
                            h3 {
                                font-size: 1.875rem;
                                line-height: 4.3125rem;
                            }

                            p {
                                margin-bottom: 2rem;
                                width: 34.375rem;
                                font-size: 1.5rem;
                                line-height: 2.8rem;
                                margin-top: 1rem;
                                text-align: justify;
                                font-family: "AlibabaPuHuiTiL";
                            }
                        }
                    }
                    .m_swipers {
                        display: none;
                    }
                }

                &_imgBox {
                    width: 55%;
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;

                    &_shows {
                        width: 49%;
                        height: 18.375rem;
                        background-color: #f5f5f5;
                        margin-bottom: 1.0625rem;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }

    &_client {
        .client-each {
            padding-top: 6.25rem;
            display: flex;
            justify-content: left;
            flex-wrap: wrap;

            div {
                width: 137px;
                height: 8.5625rem;
                background-color: #9999;
                margin-bottom: 1.5rem;
                margin-right: 2.125rem;

                &:nth-child(10n) {
                    margin-right: 0;
                }
            }
        }
    }
}

@import "css/m-competition";
</style>
