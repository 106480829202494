<template>
    <div class="system" :class="{ m_system: $store.state.screenType }">
        <TitleH>
            <transition slot="header">
                <span>{{ $t("Nav[1].menuList[3]").toUpperCase() }}</span>
            </transition>
        </TitleH>
        <div class="system_items" v-if="system.length">
            <div class="system_items_img">
                <img :src="imgUrl(baseUrl,system[0].imgList[0].ImageUrl, '@t:1024x1024>')" alt="">
            </div>
            <div class="system_items_text">
                <p>{{ system[0].title }}</p>
                <div class="line">
                    <div></div>
                </div>
                <div v-html="system[0].text"></div>
            </div>
        </div>
        <div class="system_items" v-if="system.length">
            <div class="system_items_text">
                <p>{{ system[1].title }}</p>
                <div class="line">
                    <div></div>
                </div>
                <div v-html="system[1].text"></div>
            </div>
            <div class="system_items_img">
                <img :src="imgUrl(baseUrl,system[1].imgList[0].ImageUrl,'@t:800x800>')" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import TitleH from "../../../components/Index/TitleH";
import { imgUrl } from "@/utils/urlutils";
import { mapState } from "vuex";

export default {
    name: "SystemModule",
    props: ["system"],
    components: {
        TitleH,
    },
    methods: {
        imgUrl,
    },
    computed: {
        ...mapState(["baseUrl"]),
    },
};
</script>


<style scoped lang="scss">
$maxWidth: 1920px;
/*-- -------SystemModule---------- --*/
.system {
    max-width: $maxWidth;
    padding: 4.125rem 7.5rem;
    margin: 0 auto;
    ::v-deep .en_title {
        line-height: 2.125rem !important;
    }
    &_items {
        display: flex;
        justify-content: left;
        height: 37.375rem;
        margin-top: 7.9375rem;

        &_img {
            width: 50%;
            overflow: hidden;
            img {
                width: 100%;
            }
        }

        &_text {
            width: 50%;
            padding: 6.875rem 6.125rem;
            box-sizing: border-box;

            ::v-deep p {
                line-height: 4.75rem;
                font-size: 2rem;
            }

            // text-align: justify;
            font-family: "AlibabaPuHuiTiL";

            .line {
                width: 10.375rem;
                height: 0.1875rem;
                background-color: #000;
                margin: 2.75rem 0;

                div {
                    width: 25%;
                    height: 100%;
                    background-color: #ed121b;
                }
            }

            .short {
                font-size: 1.5rem;
            }
        }

        &:last-child {
            background-color: #fbfbfb;
        }
    }
}

@import "css/m-system";
</style>
