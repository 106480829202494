<template>
    <div class="education" :class="{m_education:$store.state.screenType}">
        <TitleH>
            <transition slot="header">
                <span>{{$t('Nav[1].menuList[5]').toUpperCase()}}</span>
            </transition>
        </TitleH>
        <div class="education_items"  v-if="Object.keys(education).length>0">
            <div class="education_items_img" >
                <img :src="imgUrl(baseUrl,education.imgList[0].ImageUrl,'@t:800x800>')" alt="">
            </div>
            <div class="education_items_text">
               <div class="title" v-html="education.title"></div>
                <div class="line">
                    <div></div>
                </div>
                <div class="content" v-html="education.text"></div>
            </div>
        </div>
    </div>
</template>

<script>
import TitleH from '../../../components/Index/TitleH'
import { imgUrl } from "@/utils/urlutils"
import {mapState} from 'vuex'
export default {
    name: "EducationModule",
    props:['education'],
    components: {
        TitleH
    },
    methods: {
        imgUrl
    },
    computed:{
        ...mapState(['baseUrl'])
    }

}
</script>


<style scoped lang="scss">
$maxWidth: 1920px;
/*-- -------EducationModule---------- --*/
.education {
    max-width: $maxWidth;
    padding: 4.125rem 7.5rem 21.875rem 7.5rem;
    margin: 0 auto;

    &_items {
        display: flex;
        justify-content: left;
        height: 60.125rem;
        margin-top: 7.9375rem;

        &_img {
            width: 50%;
            overflow: hidden;
            position: relative;
            img {
                width: 100%;
                position: absolute;
                top: -160px;
            }
        }

        &_text {
            width: 50%;
            padding: 6.875rem 6.125rem;
            box-sizing: border-box;
            font-family: 'AlibabaPuHuiTiL';
            text-align: justify;
            .title{
                ::v-deep p {
                    line-height: 4.75rem;
                    font-size: 2rem;
                }
            }
            p {
                line-height: 4.75rem;
                font-size: 2rem;
            }

            .line {
                width: 10.375rem;
                height: 0.1875rem;
                background-color: #000;
                margin: 2.75rem 0;

                div {
                    width: 25%;
                    height: 100%;
                    background-color: #ED121B;
                }
            }

            .content {
                ::v-deep p{
                    font-size: 1.5rem;
                    line-height: 2.625rem;
                    margin-top: 2.25rem;
                }
            ;
            }
        }

        &:last-child {
            background-color: #FBFBFB;
        }
    }
}

@import 'css/m-education';
</style>
