<template>
    <div class="Swiper">
        <div class="swiper-container swiperCd" ref="mySwiper" >
            <div class="swiper-wrapper">
                <div class="competition_skill_main swiper-slide" :class="{m_main:$store.state.screenType}"
                     v-for="(value,index) in list" :key="index">
                    <div class="main-child" v-for="(val,i) in value" :key="i">
                        <div class="imgBox">
                            <img :src="imgUrl(baseUrl,val.ImageUrl,'@t:640x640>')" alt="">
                        </div>
                        <h3>{{ val.Text }}</h3>
                        <div class="text">
                            {{ val.Descript }}
                        </div>
                    </div>
                </div>
            </div>
            <!-- 如果需要分页器 -->
            <div class="swiper-pagination show-pagination"></div>
            <!-- 如果需要导航按钮 -->
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
            <slot name="navbar"></slot>
        </div>
    </div>
</template>

<script>
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import { imgUrl } from "@/utils/urlutils"
import Swiper from "swiper"
import { mapState } from 'vuex'

export default {
    name: "CompetitionSwiper",
    components: {},
    props: ['list'],
    data () {
        return {}
    },
    created () {
        // if (this.list.length) {
        //     this.createSwiper()
        // }


        this.$nextTick(()=>{//在这个函数里面，因为数据改变导致页面生成新的真实dom，全部渲染完成了
            // new Swiper(".swiperCd",{
            //    loop:true,
            //    pagination:{
            //    el:".swiper-pagination",
            //    //自动开始
            //     autoplay:true,
            //     delay:1000,
            //     clickable: true,
            //     autoplayDisableOnInteraction: false,
            //     paginationClickable: true
            // }})  
            if (this.list.length) {
                this.createSwiper()
            }
        })
      
    },
    mounted () {

    },
    methods: {
        imgUrl,
        createSwiper () {
            let swiperCom = new Swiper('.swiperCd', {
                observer: true,         //自动初始化swiper
                observeParents: true,   //自动初始化swiper
                //自动开始
                autoplay: {
                    delay: 5000, //时间间隔
                    disableOnInteraction: false //*手动操作轮播图后不会暂停*
                },
                loop: true,// 循环模式选项
                //分页器
                pagination: {
                    el: '.show-pagination',
                    clickable: true,
                    autoplayDisableOnInteraction: false,
                    paginationClickable: true
                }
            })
        }
    },
    computed: {
        ...mapState(['baseUrl'])
    }

}
</script>


<style scoped lang="scss">
.competition_skill_main {
    //padding: 3.125rem 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .main-child {
        width: 32%;
        text-align: center;

        .imgBox {
            width: 100%;
            height: 22.8125rem;
            background-color: #999;

            img {
                width: 100%;
                height: 100%;
            }
        }

        h3 {
            padding: 1.25rem 0;
            font-size: 1.875rem;
        }

        .text {
            padding: 0 4rem;
            font-size: 1.5rem;
            line-height: 3rem;
            font-family: 'AlibabaPuHuiTiL';
        }
    }
}

.Swiper {
    height: 42.0625rem;
    margin-top: 3.5rem;
    //overflow: hidden;
    .swiper-container {
        //overflow: auto;
        height: 42.9375rem;
        /* 分页器  */

        .swiper-pagination {
            right: calc(50% - 3.75rem);
            left: auto;
            bottom: 1rem;
            margin: 0 auto;
            width: auto;

            ::v-deep .swiper-pagination-bullet {
                width: 2.3125rem;
                height: 0.1875rem;
                border-radius: 0.5rem;
                background-color: #B0B1B5;
                opacity: 1;

            }

            .swiper-pagination-bullet-active {
                background-color: red !important;
            }
        }


    }

}

.m_main {
    .main-child {

        width: 48%;

        .text {
            padding: 0 1rem;
        }

        &:last-child {
            display: none;
        }
    }
}
</style>
