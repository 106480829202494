<template>
    <div class="Swiper">
        <div class="swiper-container" ref="mySwiper" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(value,index) in list">
                    <img :src="imgUrl(baseUrl,value, '@t:640x640>')" alt="">
                </div>

                <!-- <div class=" swiper-slide">
                    <img src="../../../../assets/index_banner_02.png" alt="">
                </div>
                <div class=" swiper-slide">
                    <img src="../../../../assets/index_banner_02.png" alt="">
                </div>
                <div class=" swiper-slide">
                    <img src="../../../../assets/index_banner_02.png" alt="">
                </div>
                <div class=" swiper-slide">
                    <img src="../../../../assets/index_banner_02.png" alt="">
                </div> -->
            </div>
            <!-- 如果需要分页器 -->
            <div class="swiper-pagination"></div>
            <!-- 如果需要导航按钮 -->
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
            <slot name="navbar"></slot>
        </div>
    </div>
</template>

<script>
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import Swiper from "swiper"
import { imgUrl } from "@/utils/urlutils"
import { mapState } from 'vuex'

export default {
    name: "CompetitionSwiper",
    components: {},
    props: ['list'],
    data () {
        return {}
    },
    mounted () {
        new Swiper('.swiper-container', {
            //自动开始
            autoplay: {
                delay: 5000, //时间间隔
                disableOnInteraction: true //*手动操作轮播图后不会暂停*
            },
            observer: true,//自动初始化swiper
            observeParents: true,//自动初始化swiper
            loop: true,// 循环模式选项
            //分页器
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            }
        })
    },
    computed: {
        ...mapState(['baseUrl'])
    },
    methods: {
        imgUrl,
        mouseEnter () {
            //console.dir(this.$refs.mySwiper)
            this.$refs.mySwiper.swiper.autoplay.stop();
        },

        mouseLeave () {
            this.$refs.mySwiper.swiper.autoplay.start();
        }
    }

}
</script>


<style scoped lang="scss">


.Swiper {

    .swiper-container {
        //overflow: auto;
        //height: 42.9375rem;
        height: 38rem;
        /* 分页器  */
        .swiper-slide {
            height: 91%;
        }

        .swiper-pagination {
            right: calc(50% - 6.8125rem);
            left: auto;
            bottom: 1rem;
            margin: 0 auto;
            width: auto;

            ::v-deep .swiper-pagination-bullet {
                width: 2.3125rem;
                height: 0.1875rem;
                border-radius: 0.5rem;
                background-color: #B0B1B5;
                opacity: 1;

            }

            .swiper-pagination-bullet-active {
                background-color: red !important;
            }
        }


    }

}


</style>
